const moduleFileName = `remoteEntry.js?cb=${Date.now()}`;
const baseUrlDev = "https://backoffice-dev02.autoproff.com";
const baseUrlMarketplace = "https://marketplace.apis-dev02.autoproff.com";
const baseUrlLocal = "https://local.autoproff.com";

const shellFragment = {
  SHELL: {
    moduleName: "shell",
    port: 8080,
  },
};

const remoteFragments = {
  C2B: {
    moduleName: "C2B",
    port: 1009,
    domainNameOnS3: "c2b",
    fragmentNameOnS3: "c2b",
    get moduleDOMVariable() {
      return `${this.moduleName}Url`;
    },
    get subDomain() {
      return `/${this.domainNameOnS3}`;
    },
    get federationConfig() {
      return `${this.moduleName}@[window.${this.moduleDOMVariable}]/${moduleFileName}`;
    },
  },
  MANAGED_FRAGMENT: {
    moduleName: "managedFragment",
    port: 1008,
    domainNameOnS3: "ap-managed",
    fragmentNameOnS3: "managed-fragment",
    get moduleDOMVariable() {
      return `${this.moduleName}Url`;
    },
    get subDomain() {
      return `/${this.domainNameOnS3}`;
    },
    get federationConfig() {
      return `${this.moduleName}@[window.${this.moduleDOMVariable}]/${moduleFileName}`;
    },
  },
  BUY_NOW_FRAGMENT: {
    moduleName: "buyNowFrontend",
    port: 8083,
    domainNameOnS3: "buy-now-frontend",
    fragmentNameOnS3: "buyNowFrontend",
    get moduleDOMVariable() {
      return `${this.moduleName}Url`;
    },
    get subDomain() {
      return `/${this.domainNameOnS3}`;
    },
    get federationConfig() {
      return `${this.moduleName}@[window.${this.moduleDOMVariable}]/${moduleFileName}`;
    },
  },
  MARKETPLACE: {
    moduleName: "marketplaceFragment",
    port: 1010,
    domainNameOnS3: "marketplace-microfrontend",
    fragmentNameOnS3: "marketplace-fragment",
    get moduleDOMVariable() {
      return `${this.moduleName}Url`;
    },
    get subDomain() {
      return `/${this.domainNameOnS3}`;
    },
    get federationConfig() {
      return `${this.moduleName}@[window.${this.moduleDOMVariable}]/${moduleFileName}`;
    },
  },
  RETAIL_PRICE_REPORTS: {
    moduleName: "retailPriceReportsFragment",
    port: 1012,
    domainNameOnS3: "ap-retail-price-reports-fragment",
    fragmentNameOnS3: "retail-price-reports-fragment",
    get moduleDOMVariable() {
      return `${this.moduleName}Url`;
    },
    get subDomain() {
      return `/${this.domainNameOnS3}`;
    },
    get federationConfig() {
      return `${this.moduleName}@[window.${this.moduleDOMVariable}]/${moduleFileName}`;
    },
  },
};

module.exports = {
  remoteFragments,
  baseUrlDev,
  baseUrlLocal,
  baseUrlMarketplace,
  moduleFileName
};
